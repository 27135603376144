/* eslint-disable max-lines */
import Grid from '@material-ui/core/Grid';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import FieldText from 'components/Field/Text';
import Translate from 'components/Translate';
import { enProductType } from 'enums/enProductType';
import { IStuddentModel } from 'interfaces/ICartModel';
import { enTheme, IProductData } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { parameterService } from 'services/ParameterService';
import { paymentService } from 'services/PaymentService';

import SuggestEmail from '../UserForm/SuggestEmail';
import { currencyService } from 'services/CurrencyService';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Fade from '@material-ui/core/Fade';
import { Hidden } from '@material-ui/core';
import { enCurrency } from 'enums/enCurrency';
import { configService } from 'services/ConfigService';
import { WithStyles } from 'shared/decorators/withStyles';
import { DDI } from '../UserForm/DDI';
import SaleRecoveryStudentAndPayerData from '../SaleRecoveryStudentAndPayerData';

interface IState extends IStateBase {
  model: IStuddentModel;
  currency: enCurrency;
  suggest: boolean;
  cart: IProductData;
  lock: boolean;
  showDocument: string;
  samePerson: boolean;
  confirmEmail: string;
  personType: string;
  templateId: enTheme;
}

interface IProps {
  formSubmitted?: boolean;
  classes?: any;
}

@WithStyles(() => ({
  containerCellPhone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    padding: '0.25rem',
    marginTop: '0.5rem',

    '@media screen and (max-width: 960px)': {
      marginTop: '0.25rem'
    },

    '&.small': {
      marginTop: '0.875rem'
    }
  },

  itemsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: '-0.625rem'
  },

  containerItemDDI: {
    height: '50px',
    flex: 1,
    marginRight: '0.25rem',

    '&.small': {
      marginTop: '-6px',
      '@media screen and (max-width: 960px)': {
        marginTop: '0.25rem'
      }
    },

    '@media screen and (max-width: 960px)': {
      height: '54px'
    }
  },
  ItemSpan: {
    fontSize: '1rem',
    color: '#636871',

    '@media screen and (max-width: 960px)': {
      display: 'none',
      fontSize: '0.75rem'
    },

    '&.small': {
      fontSize: '0.75rem',
      '@media screen and (max-width: 960px)': {
        display: 'block',
        marginTop: '-6px'
      }
    }
  },

  itemCellPhone: {
    flex: 4
  }
}))
export default class StuddentForm extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      suggest: false,
      currency: undefined,
      cart: undefined,
      model: undefined,
      lock: false,
      showDocument: '0',
      samePerson: false,
      confirmEmail: '',
      personType: '',
      templateId: null
    };
  }

  componentDidMount() {
    this.bindModel(paymentService.getCart().map((c) => c.studdent || {}));
    this.observeState({ currency: null }, currencyService.getCurrency());
    this.observeState({ cart: null }, cartService.getCart());

    cartService
      .getCart()
      .bindComponent(this)
      .filter((cart) => !!cart.user)
      .map((cart) => cart.user.lock)
      .subscribe((lock) => {
        this.setState({ lock });
      });

    paymentService
      .getCart()
      .bindComponent(this)
      .subscribe((cart) => this.setState({ personType: cart.personType, confirmEmail: cart.confirmEmail, samePerson: cart.samePerson }));

    parameterService
      .getAll()
      .bindComponent(this)
      .subscribe((params) => {
        this.setState({ showDocument: params.sdoc });
      });

    configService
      .getConfig()
      .take(1)
      .filter((config) => !!config?.template.id)
      .map((config) => config.template.id as enTheme)
      .subscribe((templateId) => this.setState({ templateId }));
  }

  componentDidUpdate() {
    if (this.state.formSubmitted != this.props.formSubmitted) {
      this.setState({ formSubmitted: this.props.formSubmitted });
    }
  }

  autoSuggest = (email: any) => {
    this.setState({ suggest: email.includes('@') });
  };

  autoComplete = (value: any) => {
    const email = `${this.state.model.email.split('@')[0]}${value}`;

    paymentService.setStudentData({ email });
    this.setState({ suggest: false });
  };

  disableSuggest = () => {
    setTimeout(() => this.setState({ suggest: false }), 300);
  };

  setEmail = (email: any) => {
    this.autoSuggest(email);
    paymentService.setStudentData({ email });
  };

  setPhone = (phone: any) => {
    paymentService.setStudentData({ phone });
  };

  setConfirmEmail = (confirmEmail: any) => {
    paymentService.setData({ confirmEmail });
  };

  setName = (name: any) => {
    paymentService.setStudentData({ name });
  };

  setDocument = (document: any) => {
    paymentService.setStudentData({ document });
  };

  render() {
    if (!this.state.model) {
      return <div />;
    }

    const { classes } = this.props;

    const { cart, lock, personType, confirmEmail, samePerson, templateId } = this.state;
    const { ead } = cart.config;
    const showConfirmEmail = samePerson && cart.config.askEmailConfirmation;

    const model = this.state.model || {};

    if (ead && lock) {
      paymentService.setPaymentData(0, { document: cart.user?.document });
      return <SaleRecoveryStudentAndPayerData payer={cart.user} student={cart.student} />;
    }

    return (
      <div className="chk-user-form">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="tooltip-card">
              <Hidden xsDown>
                <Tooltip
                  id={`tooltip-student-name`}
                  placement="top"
                  classes={{
                    popper: 'card-popper',
                    tooltip: 'card-tooltip-text'
                  }}
                  disableTouchListener={true}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={<Translate term="Este nome aparecerá no diploma de conclusão" />}
                >
                  <div className="question-mark theme-button">?</div>
                </Tooltip>
              </Hidden>
              <FieldText
                id="name_student"
                disabled={lock}
                label={<Translate term="Nome Completo do Aluno" />}
                validation="required|name|max:100"
                value={model.name}
                onChange={this.setName}
                inputProps={{
                  className: `theme-input ${lock ? 'disabledField' : ''}`
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-name'
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={cart.config.productType == enProductType.TICKET ? 6 : 12}>
            <div className="tooltip-email-information">
              <FieldText
                id="email_student"
                disabled={lock}
                label={<Translate term="E-mail do Aluno" />}
                validation="required|email|max:150"
                value={model.email}
                type="email"
                mask="nospace"
                onChange={this.setEmail}
                onBlur={this.disableSuggest}
                inputProps={{
                  className: `theme-input ${lock ? 'disabledField' : ''}`
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-email'
                }}
              />
              {this.state.suggest && <SuggestEmail email={model.email.split('@')[1]} autoComplete={this.autoComplete} />}
            </div>
          </Grid>

          {showConfirmEmail && (
            <Grid item xs={12} sm={cart.config.productType == enProductType.TICKET ? 6 : 12}>
              <FieldText
                id="confirmation"
                autoComplete="off"
                disabled={lock}
                label={<Translate term={'Confirmar e-mail'} />}
                validation={`required|email|max:150|equal:${model.email}`}
                value={confirmEmail}
                type="email"
                mask="nospace"
                onChange={this.setConfirmEmail}
                onPaste={(e) => e.preventDefault()}
                inputProps={{
                  className: `theme-input ${lock ? 'disabledField' : ''}`
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-email'
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={cart.config.productType == enProductType.TICKET ? 6 : 12}>
            <Grid container className={classes.itemsContainer}>
              <Grid item className={templateId === enTheme.PADRAO ? classes.containerItemDDI : `${classes.containerItemDDI} small`}>
                <span className={templateId === enTheme.PADRAO ? classes.ItemSpan : `${classes.ItemSpan} small`}>
                  <Translate term={'DDI'} />
                </span>
                <DDI title={'Selecione o DDI'} />
              </Grid>

              <FieldText
                id="cellphone_student"
                disabled={lock}
                label={<Translate term="Celular do Aluno" />}
                validation={personType === 'E' ? 'required|min:3|max:36' : 'required|min:10|max:11'}
                value={model.phone}
                mask={personType === 'E' ? null : 'phone'}
                type="tel"
                onChange={this.setPhone}
                inputProps={{
                  className: `theme-input ${lock ? 'disabledField' : ''}`
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-phone'
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FieldText
              id="document_student"
              disabled={lock}
              label={<Translate term={personType === 'E' ? 'Document ID / VAT / TAX ID' : 'CPF do Aluno'} />}
              validation={personType === 'E' ? 'required|min:3|max:32' : 'required|cpf'}
              value={model.document}
              mask={personType === 'E' ? null : 'cpf'}
              inputProps={{
                className: `theme-input ${lock ? 'disabledField' : ''}`,
                pattern: '[0-9]*',
                inputMode: 'numeric',
                type: 'tel'
              }}
              InputLabelProps={{
                className: 'theme-label'
              }}
              onChange={this.setDocument}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
