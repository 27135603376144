import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { cartService } from './CartService';
import { IFacebookCookie, IPixelModel } from 'interfaces/IPixelModel';
import { apiService } from 'shared/services';
import { PIXEL_URL } from 'settings';
import { paymentService } from './PaymentService';
import { of } from 'rxjs/observable/of';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import { Cookies } from 'react-cookie';
import * as storageService from 'shared/services/storage';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { empty } from 'rxjs/observable/empty';
import { cookieService } from './CookieService';
import { IProductData } from 'interfaces/IProductData';
import { IPaymentResult } from 'interfaces/IPaymentResult';
import { symbolUrl } from 'shared/utils/symbolUrl';
import { CART_SSID } from '../settings';

export enum enPixelPath {
  CHECKOUT = 'checkout',
  CREDITCARD = 'creditcard',
  BANKSLIP = 'bankslip',
  BANKSLIP_VIEW = 'bankslip-view',
  PIX = 'pix',
  PAGDIVIDIDO = 'pagdividido'
}

export interface IPixelResponse {
  urlProducer?: string;
  urlAfiliate?: string;
  scripts?: IPixelScript;
  facebookCookies?: IFacebookCookie;
  path?: string;
  frame?: string;
}

export interface IPixelScript {
  analytics: IPixelBody;
  analyticsG4?: IPixelBody;
  infusionsoft: IPixelBody;
  linkedin: IPixelBody;
  tagmanager: IPixelBody;
  hubspot: IPixelBody;
  tiktok?: IPixelBody;
}

export interface IPixelBody {
  readonly body: string;
  readonly head: string;
}

export class PixelService {
  private pixelPath$ = new ReplaySubject<enPixelPath>(1);
  private pixelModel$ = new ReplaySubject<IPixelModel>(1);
  private hasTagmanager$ = new BehaviorSubject<boolean>(true);
  private pixelResponse$ = new ReplaySubject<IPixelResponse>(1);

  getPixelModel(cart: IProductData): IPixelModel {
    const params = new URLSearchParams(window.location.search);
    const externalIdPrefix = 'external_id';
    const externalIdSufix = cart.config.affiliateId || cart.config.producerId;
    let referrerParams = null;

    if (document.referrer) {
      referrerParams = new URLSearchParams(new URL(document.referrer).search);
    }

    let model: IPixelModel = {
      contentId: cart.contentId,
      parentId: cart.pixelContentId,
      producerId: cart.config.producerId,
      affiliateId: cart.config.affiliateId || null,
      fbc:
        cookieService.getCookie(`_fbc_${cart.pixelContentId}`) ||
        cookieService.getCookie('_fbc') ||
        params.get('fbclid') ||
        referrerParams?.get('fbclid') ||
        null,
      fbp: cookieService.getCookie(`${externalIdPrefix}_${externalIdSufix}`) || cookieService.getCookie(externalIdPrefix) || null,
      referrer: window.location.href,
      eventId: cart.transactionKey || CART_SSID,
      enabledThirdPartyCookies: storageService.checkStorage()
    };

    return model;
  }

  setPath(path: enPixelPath): void {
    this.pixelPath$.next(path);
  }

  getPath(): Observable<enPixelPath> {
    return this.pixelPath$.asObservable();
  }

  init(): Observable<IPixelResponse> {
    cartService
      .getCart()
      .take(1)
      .map((cart) => this.getPixelModel(cart))
      .subscribe((model: IPixelModel) => {
        this.pixelModel$.next(model);
      });

    Observable.combineLatest(cartService.getCart(), paymentService.getCart(), paymentService.getPaymentResult())
      .map(([cart, payment, paymentResult]) => {
        let model = this.getPixelModel(cart);

        let phone = '';
        if (cart?.user?.personType !== 'E') {
          phone = payment.phone;
          phone = `${phone?.length > 11 ? '+' : '+55'}${phone}`;
        }

        model.user = {
          name: payment.name || '',
          email: payment.email || '',
          phone
        };

        model.payment = {
          product: cart.content.title,
          price: paymentResult.receivedAmount,
          quantity: cart.products[0].quantity || 1,
          currency: cart.config.currency,
          paymentType: cart.config.paymentType,
          normalizedPaymentType: paymentResult.paymentMethod || payment.payment[0].paymentMethod,
          transactionId: cart.transactionKey,

          thankyouUrl: paymentResult.thankyouUrl || null,
          campaign: cart.config.pixel.campaign || null,
          subscription: cart.subscription || null
        };

        return model;
      })
      .subscribe((model: IPixelModel) => {
        this.pixelModel$.next(model);
      });

    return this.pixelPath$
      .switchMap((path) => {
        return Observable.combineLatest(of(path), this.pixelModel$.asObservable()).take(1);
      })
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .switchMap(([path, model]) => {
        return apiService
          .post<IPixelResponse>(`${PIXEL_URL}/pixel/${path}`, model, {}, { maxAttempts: 5 })
          .catch(() => {
            console.log('failed to post pixel');
            this.pixelResponse$.next({} as IPixelResponse);

            return empty<IPixelResponse>();
          })
          .map((pixel) => {
            return { path, ...pixel, model };
          });
      })
      .do((pixel) => {
        if (pixel.path === enPixelPath.CHECKOUT) {
          const externalIdPrefix = 'external_id';
          const externalIdSufix = pixel.model.affiliateId || pixel.model.producerId;

          if (pixel?.facebookCookies?.fbc) {
            cookieService.setCookie(`_fbc_${pixel.model.contentId}`, pixel.facebookCookies.fbc, 90);
          }

          if (pixel?.facebookCookies?.fbp) {
            cookieService.setCookie(`${externalIdPrefix}_${externalIdSufix}`, pixel.facebookCookies.fbp, 90);
          }

          this.pixelResponse$.next({ ...pixel });
        }

        if (pixel.scripts.tagmanager) {
          this.unsetTagmanager();
        }
      })
      .catch(() => {
        console.log('failed to set pixel');
        return empty<IPixelResponse>();
      });
  }

  setAffiliationCookie() {
    return parameterService
      .get(enParameters.a)
      .filter((param) => !!param)
      .switchMap(() => this.pixelModel$)
      .filter((pixel) => !!pixel.contentId)
      .map((pixel) => {
        return `https://my.eduzz.com/lp/edz/${pixel.contentId}/${pixel.producerId}`;
      });
  }

  sendPurchaseData(result: IPaymentResult) {
    return Observable.combineLatest(paymentService.getCart(), cartService.getCart(), this.pixelModel$, this.pixelResponse$)
      .take(1)
      .map(([payment, cart, pixelModel, response]) => {
        let url = result?.thankyouUrl;

        if (!url) {
          url = `${window.location.protocol}//${window.location.host}/thankyou/${pixelModel.contentId}/${payment.ssid}`;
        }

        const params = new URLSearchParams(new URL(window.location.href)?.search);

        return {
          transactionKey: payment.ssid,
          campaignKey: cart.config.pixel.campaignKey,
          url: url + symbolUrl(url) + 'test_event=' + params.get('test_event'),
          data: {
            fbc: pixelModel?.fbc,
            fbp: pixelModel?.fbp
          },
          product: {
            producerId: pixelModel.producerId,
            affiliateId: pixelModel.affiliateId ?? 0,
            contentId: pixelModel.contentId,
            parentId: pixelModel.parentId,
            subscription: cart.subscription ?? {},
            quantity: cart.products[0].quantity
          },
          user: {
            name: pixelModel?.user?.name ?? payment.name,
            phone: pixelModel?.user?.phone ?? payment.phone,
            email: pixelModel?.user?.email ?? payment.email,
            agent: response?.facebookCookies?.agent,
            ip: response?.facebookCookies?.ip
          },
          payment: {
            paymentType: cart.config.paymentType,
            method: payment.payment[0].paymentMethod,
            price: cart.price,
            currency: cart.config.currency
          }
        };
      })
      .switchMap((data) => {
        return apiService.post(
          `${PIXEL_URL}/pixel-server/transaction`,
          { ...data },
          {
            timeout: 10000
          },
          { maxAttempts: 3 }
        );
      });
  }

  setPixel(model: IPixelModel) {
    this.pixelModel$.next(model);
  }

  hasCookie(contentId: string): boolean {
    const cookie = new Cookies();

    if (cookie.get(`pixel-${contentId}`)) {
      cookie.remove(`pixel-${contentId}`, { path: '/' });

      return true;
    }

    return false;
  }

  unsetTagmanager() {
    this.hasTagmanager$.next(false);
  }

  hasTagmanager() {
    return this.hasTagmanager$.asObservable();
  }
}

// tslint:disable-next-line:variable-name
export const PixelServiceFactory = () => new PixelService();

export const pixelService = PixelServiceFactory();
