/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Error from 'components/Error';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentStatus } from 'enums/enPaymentStatus';
import { IPaymentResult } from 'interfaces/IPaymentResult';
import { IProductConfig, IProductData } from 'interfaces/IProductData';
import get from 'lodash/get';
import React from 'react';
import { Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { captchaService } from 'services/CaptchaService';
import { cartService } from 'services/CartService';
import { configService } from 'services/ConfigService';
import goofyService from 'services/GoofyService';
import { enLegacyPixelPath, legacyPixelService } from 'services/LegacyPixelService';
import { paymentService } from 'services/PaymentService';
import { enPixelPath, pixelService } from 'services/PixelService';
import { translateService } from 'services/TranslateService';
import { GOOFY_ID, PREVIEW_MODE } from 'settings';
import { WithRouter } from 'shared/decorators/withRouter';
import { WithStyles } from 'shared/decorators/withStyles';
import { HistoryContext } from 'shared/HistoryContext';
import { showError } from 'shared/services/dialog';

import { unescapeHtml } from 'functions';
import { IGoofyData } from 'interfaces/IGoofyData';
import { internationalService } from 'services/InternationalService';
import { loadTimeService } from '../../services/LoadTimeService';
import { paymentProgressService } from '../../services/PaymentProgressService';
import { showAlert } from '../../shared/services/dialog';
import BackRedirect from '../BackRedirect';
import HotsiteTheme from './Templates/Hotsite';
import MobileTheme from './Templates/Mobile';
import WidgetTheme from './Templates/Widget';
import { Timer } from './Timer';

export interface ITemplateState extends IStateBase {
  cart: IProductData;
  config: IProductConfig;
}

interface IState extends IStateBase, ITemplateState {
  loading: boolean;
  config: IProductConfig;
  hasTimer: boolean;
}

export interface IPayProps {
  onPay: () => Observable<boolean>;
}

interface IProps {}

export const PayContext = React.createContext<() => any>(() => {});

@WithRouter()
@WithStyles(() => ({
  component: {
    '& .cart-container': {
      margin: '0 auto'
    },
    '& .cart-container-scarcity-timer': {
      paddingTop: 65
    }
  }
}))
export default class CartPage extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      cart: null,
      config: null,
      hasTimer: false
    };
  }

  componentDidMount() {
    Observable.of(this.props.match.params.id)
      .combineLatest(internationalService.getSelectedCountry())
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .debounceTime(50)
      .concatMap(([id, country]) => {
        return cartService
          .loadContent(id, country.currency.code, country.isoAlpha3)
          .loader()
          .catch((err) => showError(err));
      })
      .bindComponent(this)
      .subscribe(() => {
        this.setState({
          loading: false
        });
      });
    // Goofy para envio de dados do usuário
    paymentService
      .getCart()
      .filter((paymentCart) => !!(paymentCart.name && paymentCart.phone && paymentCart.email))
      .debounceTime(5000)
      .map((paymentCart) => {
        const { name, phone, email, ddi, document } = paymentCart;
        const formattedPhoneNumber = ddi ? `${ddi}${phone}` : phone;
        return {
          name,
          phone: formattedPhoneNumber,
          email,
          document
        };
      })
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .bindComponent(this)
      .subscribe((data) => {
        // set user data
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'user_data',
          data: {
            ...data
          },
          subtract: 5000
        });
      });

    // Goofy para envio de dados de endereço
    cartService
      .getCart()
      .filter((cart) => !!(cart.config.askAddressBeforePayment || cart.config.askAddressAfterPayment))
      .switchMap(() => paymentService.getCart())
      .filter((cart) => !!cart.address)
      .debounceTime(2000)
      .map((cart) => {
        return {
          address: {
            postalCode: cart.address.postalCode,
            street: cart.address.street,
            number: cart.address.number,
            district: cart.address.district,
            complement: cart.address.complement,
            province: cart.address.province,
            city: cart.address.city,
            cityId: cart.address.cityId,
            country: cart.address.country
          }
        };
      })
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .bindComponent(this)
      .subscribe((data) => {
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'address_data',
          data,
          subtract: 5000
        });
      });

    // Goofy rendered
    cartService
      .getCart()
      .first()
      .filter(() => {
        const path = window.location.pathname;
        return !!path.match(/^\/(\d+|c_[a-f0-9]+)/);
      })
      .bindComponent(this)
      .subscribe((cart) => {
        loadTimeService.track(cart.contentId, cart.isCached, cart.config.producerId || null);

        // Goofy de renderização
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'rendered',
          data: {}
        });
      });

    // Goofy mounted
    cartService
      .getCart()
      .filter((cart) => !cart.isCached)
      .first()
      .combineLatest(cartService.getConfig(), internationalService.getSelectedCountry())
      .first()
      .bindComponent(this)
      .subscribe(([cart, config, selectedCountry]) => {
        const campaignKey = cart.tracker.campaignKey.startsWith('g/') ? null : cart.tracker.campaignKey;
        const campaignId = String(cart.tracker.campaignId).startsWith('g/') ? null : String(cart.tracker.campaignId);

        // Goofy de carregamento real do carrinho
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'mounted',
          data: {
            adBlock: window.AD_BLOCK || false,
            contentId: `${cart.contentId}`,
            firstProductType: cart.config.productType,
            coupon: (cart.coupon && cart.coupon.code) || null,
            couponValue: Number(cart.coupon && cart.coupon.value) || null,
            producerId: cart.config.producerId || null,
            affiliateId: Number(cart.config.affiliateId) || null,
            campaign: campaignId || null,
            campaignKey: campaignKey || null,
            templateId: config.template.id,
            customized: config.template.customized || false,
            isOneClick: config.isOneClick,
            isOneClickMode: config.isOneClick,
            amount: Number(cart.realPrice) || Number(cart.price) || 0,
            hasEmailConfirmation: config.askEmailConfirmation || false,
            cartPrice: Number(cart.realPrice) || Number(cart.price) || 0,
            offerOrderBump: !!(cart.orderBump && cart.orderBump.length > 0),
            tracker: {
              utmMedium: cart.tracker.utmMedium,
              utmSource: cart.tracker.utmSource,
              utmCampaign: cart.tracker.utmCampaign,
              utmContent: cart.tracker.utmContent
            },
            orderBumpItems: (cart.orderBump || []).map((bump) => {
              return {
                id: Number(bump.contentId),
                title: bump.title,
                realPrice: bump.realPrice
              };
            }),
            transactionKey: cart.transactionKey,
            isLaunch: cart.config.isLaunch,
            askAddress: !!(cart.config.askAddressBeforePayment || cart.config.askAddressAfterPayment),
            askAddressBeforePayment: !!cart.config.askAddressBeforePayment,
            askAddressAfterPayment: !!(cart.config.askAddressAfterPayment && !cart.config.askAddressBeforePayment),
            enablePaymentWithoutLimit: !!config.enablePaymentWithoutLimit,
            acceptMoreThan12Installments: cart.config.maxInstallments > 12,
            offer: {
              key: cart?.offer?.key,
              name: cart?.offer?.name
            },
            selectedCountry: selectedCountry.isoAlpha3
          }
        });
      });

    /*
      legacyPixelService: Should be removed in not too distance future
    */
    legacyPixelService.track(enLegacyPixelPath.CHECKOUT);

    pixelService.setPath(enPixelPath.CHECKOUT);
    this.observeState({ config: null }, cartService.getConfig());
    this.observeState({ cart: null }, cartService.getCart());

    // Scarcity Timer
    configService
      .getConfig()
      .filter((config) => !!config.template.styles.shortageTimer)
      .map((config) => config.template.styles.shortageTimer)
      .subscribe((shortageTimer) => this.setState({ hasTimer: shortageTimer.isActive }));
  }

  pay = () => {
    const cookie = new Cookies();
    if (PREVIEW_MODE) {
      console.log('cant pay on preview mode');
      return of(true);
    }

    return cartService
      .getCart()
      .filter((c) => !c.isCached)
      .take(1)
      .switchMap(() => paymentService.pay())
      .retryWhen((errors$) => {
        return errors$.switchMap((err) => {
          const errorCode = get(err, 'data.code', null);

          if (errorCode === 'CAP_A') {
            return captchaService.showCaptcha();
          }

          if (errorCode === 'CRT_A' || errorCode === 'CHE_B') {
            showAlert('O produto foi atualizado, precisamos recarregar a pagina.').subscribe(() => window.location.reload());
          }

          return Observable.throw(err);
        });
      })
      .logError()
      .catch((err) => {
        const code: string = (err && err.data && err.data.code) || '';
        if (code === 'MAN_A') {
          window.location.reload();
        }

        // Goofy de falha de pagamento
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'payment_failed',
          data: { errorCode: code }
        });

        paymentService
          .getCart()
          .take(1)
          .subscribe((payment) => {
            const moreThan12InstallmentsFailed =
              paymentService.getMoreThan12InstallmentsFailed() || payment.payment.some((p) => p.numberInstallments > 12);

            paymentService.setMoreThan12InstallmentsFailed(moreThan12InstallmentsFailed);

            if (!moreThan12InstallmentsFailed) {
              return;
            }

            goofyService.track({
              flow: 'sale',
              trackerId: GOOFY_ID,
              step: 'payment_failed',
              data: { moreThan12InstallmentsFailed }
            });
          });

        translateService.getErrorData(code).subscribe((error) => {
          paymentProgressService.setPaymentProgressStatus({
            status: enPaymentStatus.ERROR,
            message: error.message || 'Não conseguimos nos comunicar com a operadora. Atualize a página ou tente novamente mais tarde.',
            code
          });
        });

        return Observable.empty<IPaymentResult>();
      })
      .bindComponent(this)
      .combineLatest(paymentService.getCart())
      .do(([result, cart]) => {
        paymentProgressService.setPaymentProgressStatus({
          status: enPaymentStatus.SUCCESS
        });

        const data: IGoofyData = {
          amount: result.amount,
          paidAmount: result.paidAmount,
          receivedAmount: result.receivedAmount,
          paymentMethod: result.paymentMethod,
          personType: result.personType,
          paymentStatus: 1
        };

        let step = 'payment_redirect';

        if (cart.payment[0].paymentMethod !== enPaymentMethod.PAYPAL) {
          step = 'payment_success';

          data.paymentStatus = result.status;
          data.brand = result.brand;
        }

        if (typeof result.paymentWithoutLimit !== 'undefined') {
          data.paymentWithoutLimit = result.paymentWithoutLimit;
        }

        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step,
          data
        });

        const oneMinute = 60;
        const oneHour = oneMinute * 60;
        const oneDay = oneHour * 24;

        cookie.set('last_purchase', `${result.paymentMethod}:${result.key}:${new Date().toISOString()}`, {
          maxAge: oneHour
        });

        cookie.remove('he');
        if (result.he) {
          cookie.set('he', result.he, { maxAge: oneDay * 30 });
        }

        cookie.set('lastKey', result.key, { maxAge: oneHour });
        cookie.set('lastPaymentMethod', result.paymentMethod, {
          maxAge: oneHour
        });
        cookie.set('email', cart.email, { maxAge: oneDay * 365 });
      });
  };

  render() {
    const { loading, cart, config, hasTimer } = this.state;

    if (loading) {
      return null;
    }

    if (!cart) {
      return <Error />;
    }

    //  BaseComponent<IStateBase, IPayProps> }
    const templateMap: { [key: string]: any } = {
      14: HotsiteTheme,
      16: WidgetTheme,
      17: MobileTheme,
      18: MobileTheme,
      19: MobileTheme
    };

    // tslint:disable-next-line:variable-name
    const Component = templateMap[config.template.id] || templateMap[18];

    return (
      <HistoryContext.Provider value={this.props.history}>
        <div className={this.props.classes.component}>
          <Timer />
          <div className={`cart-container ${hasTimer ? 'cart-container-scarcity-timer' : ''}`}>
            <Helmet>
              <title>{unescapeHtml(cart.content.title)}</title>
              <html className={'theme_' + config.template.id} />
            </Helmet>
            <Component onPay={this.pay} />
            <BackRedirect {...config?.backRedirect} />
          </div>
        </div>
      </HistoryContext.Provider>
    );
  }
}
