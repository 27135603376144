/* eslint-disable max-lines */

import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentType } from 'enums/enPaymentType';
import { ICouponData, IProduct, IProductData, IShipping, IShippingData } from 'interfaces/IProductData';
import { Cookies } from 'react-cookie';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { paymentService } from 'services/PaymentService';
import { CACHED_MOUNT, CART_SSID, CHECKOUT_ID, GOOFY_ID, IGNORE_CACHE, IS_ECOMMERCE, PREVIEW_MODE, SHOPIARY_URL, TRACKER_DATA } from 'settings';
import { round2 } from 'shared/functions';

import { enCurrency } from 'enums/enCurrency';
import { enParameters } from 'enums/enParameters';
import { enProductType } from 'enums/enProductType';
import { enShippingType } from 'enums/enShippingType';
import { formatScripts } from 'functions';
import { ICartModel, IProductModel } from 'interfaces/ICartModel';
import { IOrderBumpResult } from 'interfaces/IOrderBumpResult';
import { BehaviorSubject } from 'rxjs';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { of } from 'rxjs/observable/of';
import { tap } from 'rxjs/operators';
import { legacyPixelService } from 'services/LegacyPixelService';
import { ticketService } from 'services/TicketService';
import { apiService } from 'shared/services';
import { showAlert, showError } from 'shared/services/dialog';
import { isInternationalCurrency } from 'shared/utils/isInternationalCurrency';
import { IProductTemplateConfig } from '../interfaces/IProductData';
import { CACHE_ID } from '../settings';
import { cacheService } from './CacheService';
import { configService } from './ConfigService';
import { currencyService } from './CurrencyService';
import goofyService from './GoofyService';
import { calcDefaultNumberInstallments, warmapCache } from './InstallmentsService';
import { parameterService } from './ParameterService';
import { scriptsService } from './ScriptsService';
import { shopifyService } from './ShopifyService';
import { socketService } from './SocketService';
import { translateService } from './TranslateService';

export interface ICartConfigReplacer {
  templateId?: number;
  template?: IProductTemplateConfig;
}

const cookie = new Cookies();

export class CartService {
  private cart$ = new ReplaySubject<IProductData>(1);
  private uniqueCart$ = this.cart$
    .asObservable()
    .map((state) => {
      (state.isCached as any) = !this.mounted;
      return state;
    })
    .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));

  private loaded = false;
  private mounted = false;

  private cartState: IProductData = null;
  private isAskAddressAfterPaymentStep$ = new BehaviorSubject<boolean>(false);
  private acceptedSubscriptionTerm$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.trackItemsGoofy();
  }

  public getCart(): Observable<IProductData> {
    return this.uniqueCart$;
  }

  public getMappedCart<T>(fn: (cart: IProductData) => T) {
    return this.getInternalCart()
      .map(fn)
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  public getConfig() {
    return configService.getConfig();
  }

  public setShippingData(products: IProductModel[], postalCode: string = null): Observable<IShippingData> {
    if (products.length === 0) {
      return Observable.empty();
    }

    return this.getInternalCart()
      .combineLatest(this.getIsAskAddressAfterPaymentStep())
      .take(1)
      .filter(([cart, isAskAddressAfterPaymentStep]) => {
        return !cart.isCached && !isAskAddressAfterPaymentStep;
      })
      .switchMap(() => paymentService.getCart())
      .switchMap((cart) => {
        return Observable.combineLatest(Observable.of(cart), shopifyService.getShippingData(cart.address), currencyService.getCurrency());
      })
      .take(1)
      .switchMap(([payment, shopifyShipping, currency]) => {
        const body = {
          transactionId: payment.ssid,
          postalCode,
          products,
          personType: payment.personType,
          shopifyShipping,
          currency
        };

        if (body.postalCode == 'undefined') {
          body.postalCode = null;
        }

        return apiService.post<IShippingData>('/shipping/estimate', body).loader();
      })
      .combineLatest(
        this.getInternalCart().take(1),
        paymentService
          .getCart()
          .take(1)
          .map((p) => p.personType),
        parameterService.get(enParameters.p).take(1)
      )
      .map(([data, cart]) => {
        warmapCache({
          installments: data.installments,
          price: data.price
        });

        data.shippingItems.forEach((i) => {
          warmapCache({
            installments: i.installments,
            price: i.totalValue
          });
        });

        paymentService.setBlockPayment(false);

        (this.cartState.installments as any) = data.installments;
        (this.cartState.price as any) = data.price;
        (this.cartState.realPrice as any) = data.realPrice;
        (this.cartState.products as any) = data.products;
        (this.cartState.tax as any) = data.tax;
        (this.cartState.realTax as any) = data.realTax;
        (this.cartState.taxedPrice as any) = data.taxedPrice;
        (this.cartState.cartPrice as any) = data.cartPrice;

        if (this.cartState.config.productType == enProductType.TICKET) {
          this.cartState.tickets.reservationData = data.reservation;

          const hasError = cart.tickets.reservationData.products.some((p) => p.success == false);

          if (hasError) {
            // tslint:disable-next-line:max-line-length
            showAlert(
              'Infelizmente a quantidade de tickets selecionada é maior que o nosso estoque atual. Tente novamente mais tarde.',
              'Ops!'
            ).subscribe();
          }
        }

        this.emit(this.cartState);

        if (this.cartState.tickets) {
          ticketService.setTimeLeft(this.cartState.tickets.reservationData.expiresIn);
        }

        paymentService.setData({
          quantity: data.products[0].quantity
        });

        this.updateInstallments();

        return data;
      })
      .catch((err) => {
        paymentService.setBlockPayment(true);

        showError(err).subscribe();
        return Observable.empty();
      });
  }

  public updateInstallments() {
    combineLatest(
      this.getInternalCart().take(1),
      parameterService.get(enParameters.p).take(1),
      parameterService.get(enParameters.np).take(1),
      paymentService
        .getCart()
        .take(1)
        .map((p) => p.personType)
    )
      .map(([cart, parametersInstallments, np, personType]) => {
        const parameterMaxInstallments = Math.max(Math.trunc(Number(np) || 0), 0);
        const maxInstallments = parameterMaxInstallments || cart.installments.length;
        const defaultInstallments = calcDefaultNumberInstallments(maxInstallments);

        const parameters = Number(parametersInstallments) > maxInstallments ? maxInstallments : Number(parametersInstallments);
        const installmentSelected = cart.installmentSelected > maxInstallments ? maxInstallments : cart.installmentSelected;

        const numberInstallments = personType === 'E' ? 1 : parameters || installmentSelected || defaultInstallments;

        paymentService.setPaymentData(0, {
          numberInstallments
        });
      })
      .subscribe();
  }

  public setShipping(hash: string, selectedShipping: IShipping): void {
    of(true)
      .map(() => {
        (this.cartState.price as any) = selectedShipping.totalValue;
        (this.cartState.taxedPrice as any) = selectedShipping.taxedPrice;
        (this.cartState.tax as any) = selectedShipping.tax;
        (this.cartState.cartPrice as any) = selectedShipping.cartPrice;
        (this.cartState.realPrice as any) = selectedShipping.realPrice;
        (this.cartState.installments as any) = selectedShipping.installments;

        this.emit(this.cartState);
        paymentService.setData({
          shipping: {
            code: selectedShipping.code,
            hash
          }
        });

        paymentService.setPaymentData(0, {
          value: selectedShipping.totalValue,
          installments: selectedShipping.installments
        });

        paymentService.setInstallmentsDefault();

        return true;
      })
      .subscribe();
  }

  public setOrderBumps(products: string[]): Observable<any> {
    return paymentService
      .getCart()
      .take(1)
      .switchMap((cart) => {
        return Observable.combineLatest(
          Observable.of(products),
          apiService.post<IOrderBumpResult>(`/bump/${this.cartState.transactionKey}/${this.cartState?.offer?.key ?? this.cartState.contentId}`, {
            products,
            personType: cart.personType
          })
        );
      })
      .map(([bump, bumpData]) => {
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'bump_selected',
          data: {
            hasOrderBump: bump.length > 0
          }
        });

        this.cartState = { ...this.cartState, ...bumpData, cartPrice: bumpData.price };
        (this.cartState.installments as any) = bumpData.installments;

        this.emit(this.cartState);
        paymentService.setPaymentData(0, {
          value: bumpData.price,
          installments: bumpData.installments
        });

        const products: IProductModel[] = [];

        this.cartState.products.map((product: IProduct) => {
          products.push({
            contentId: product.id.toString(),
            quantity: product.quantity
          });
        });

        paymentService.setData({ products });

        paymentService.setInstallmentsDefault();

        return this.cartState;
      });
  }

  public setCoupon(coupon: string, couponType: number = 1): Observable<boolean> {
    paymentService.addLock();

    return this.getInternalCart()
      .filter((cart) => !!cart.transactionKey)
      .take(1)
      .combineLatest(paymentService.getCart().take(1))
      .switchMap(([cart, payment]) => {
        const url =
          couponType === 1
            ? `/coupon/${cart.transactionKey}/product/${cart.contentId}`
            : `/coupon/${cart.transactionKey}/product/${cart.contentId}/modal`;

        return apiService.post<ICouponData>(url, {
          coupon,
          personType: payment.personType || 'F'
        });
      })
      .switchMap((coupon) => (coupon.coupon.value > 0 ? this.updateCouponData(coupon) : Observable.of(true)))
      .finally(() => {
        paymentService.removeLock();
      });
  }

  public enableAskAddress(): Observable<IProductData> {
    return this.getInternalCart()
      .take(1)
      .filter((cart) => cart.config.askAddressAfterPayment || cart.config.askAddressBeforePayment)
      .do(() => {
        (this.cartState.config.askAddressAfterPayment as any) = false;
        (this.cartState.config.askAddressBeforePayment as any) = true;

        this.emit(this.cartState);
      });
  }

  public removeCoupon(): Observable<boolean> {
    return this.getInternalCart()
      .take(1)
      .switchMap((cart) => {
        return apiService.del<ICouponData>(`/coupon/${cart.transactionKey}/product/${cart.contentId}`);
      })
      .switchMap((coupon) => this.updateCouponData(coupon));
  }

  public updateCouponData(coupon: ICouponData): Observable<boolean> {
    return Observable.of(coupon)
      .combineLatest(this.getInternalCart().take(1), paymentService.getCart().take(1))
      .map(([coupon, cart, payment]) => {
        const newCart = this.createNewCart(cart, coupon);

        // REMOVER APÓS CORRIGIDO NA API
        if (newCart.config.paymentType === enPaymentType.SUBSCRIPTION) {
          (newCart.subscription.tax as any) = coupon.price;
        }

        warmapCache(newCart);

        this.emit(newCart);

        const newValue = round2(newCart.price / payment.payment.length);

        let lastPaymentValue = newValue + (newCart.price - newValue * payment.payment.length);

        lastPaymentValue = round2(lastPaymentValue);

        payment.payment.forEach((p, key) => {
          paymentService.setPaymentData(key, {
            value: key != payment.payment.length - 1 ? newValue : lastPaymentValue,
            installments: newCart.installments
          });

          paymentService.setInstallmentsDefault();
        });

        return true;
      })
      .logError();
  }

  public disableOneClickBuy(): Observable<void> {
    cookie.remove('he');
    cookie.remove('email');

    return this.getInternalCart()
      .take(1)
      .map((cart) => {
        const newCart: IProductData = JSON.parse(JSON.stringify(cart));
        (newCart.config.isOneClick as any) = false;
        this.emit(newCart);

        return null;
      });
  }

  public getCurrentState(): Observable<IProductData> {
    return this.getInternalCart().first();
  }

  public loadContent(checkoutId: string, currency: enCurrency, country = 'BRA'): Observable<boolean> {
    let transactionKey = CART_SSID;

    const cachedMount = CACHED_MOUNT;

    let isEcommerce = false;
    let isRec = false;

    let loadObservable = parameterService
      .getAll()
      .take(1)
      .switchMap((parameters) => {
        return of(parameters);
      })
      .switchMap((parameters) => {
        const cookie = new Cookies();
        if (IS_ECOMMERCE || !!`${checkoutId}`.match(/^c_/)) {
          checkoutId = checkoutId.replace(/^c_/, '');
          parameters = { ...parameters, ec: '1' };
          isEcommerce = true;
        }

        if (!!`${checkoutId}`.match(/^r_/)) {
          checkoutId = checkoutId.replace(/^r_/, '');
          parameters = { ...parameters, rec: '1' };
          isRec = true;
        }

        const lastPurchase = cookie.get('last_purchase') || '';
        const purchaseDate = lastPurchase.replace(lastPurchase.split(':', 2).join(':') + ':', '');

        const purchaseTime = new Date(purchaseDate).getTime() || 0;
        const now = new Date().getTime();

        const diffMinutes = (now - purchaseTime) / 1000 / 60;

        if (parameters.u && diffMinutes < 20) {
          parameters.upsell = true;
          parameters.u = 1;
        }

        if (PREVIEW_MODE) {
          parameters.email = '';
          parameters.lastKey = '';
          parameters.previewMode = true;
        }

        if (parameters.u !== 1) {
          delete parameters.u;
        }

        if (!!country) {
          parameters.country = country;
        }

        return apiService.put<IProductData>(`/cart/${transactionKey}`, {
          contentId: CHECKOUT_ID,
          checkoutId,
          currency,
          parameters,
          tracker: TRACKER_DATA,
          goofyId: GOOFY_ID
        });
      })
      .catch((err) => {
        if (err.code === 'TRA_A') {
          window.location.reload();
          return Observable.empty();
        }

        return Observable.throw(err);
      })
      .map((data: IProductData) => {
        (data.contentId as string) = data.contentId;
        (data.config.scripts as any) = formatScripts(data.config.scripts);
        (data.isCached as any) = false;
        const products: IProductModel[] = [];

        data.products.map((p) =>
          products.push({
            contentId: p.id.toString(),
            quantity: p.quantity || 1
          })
        );

        const name = parameterService.getSync(enParameters.name) || '';

        if (name && name.split(' ').length < 2) {
          (data.config as any).isOneClick = false;
        }
        paymentService.setData({ products });

        scriptsService.setScripts(data.config.scripts);

        if (window.TEMPLATE_CONFIG) {
          const templateConfig: any = window.TEMPLATE_CONFIG;

          (data.config.template as any) = {
            id: templateConfig.template,
            inputStyle: 'normal',
            styles: templateConfig,
            media: {
              bannerCenter: templateConfig.bannerCenter,
              bannerTop: templateConfig.bannerTop,
              bannerTopUrl: templateConfig.bannerTopUrl,
              bannerRight: templateConfig.bannerRight,
              bannerRightUrl: templateConfig.bannerRightUrl,
              bannerYoutube: templateConfig.bannerYoutube
            }
          };
        }

        return data;
      });

    if (!this.loaded) {
      this.loaded = true;
      loadObservable = loadObservable.startWith(cachedMount);
    }

    const observable = loadObservable
      .filter((data) => !!data)
      .combineLatest(paymentService.getCart().take(1))
      .map(([data, paymentData]) => {
        if (!parameterService.getSync(enParameters.pu)) {
          parameterService.setParameter(enParameters.pu, `${data.config.maxInstallments}`);
        }

        if (data.config.shippingType === enShippingType.SHOPIARY) {
          window.location.href = `${SHOPIARY_URL}/${checkoutId}`;
        }

        if (!!data?.user?.phone && data.user.phone.startsWith('55')) {
          (data.user.phone as string) = `+${data.user.phone}`;
        }

        const currencyCart = data.config.currency;

        data.originalCheckoutId = checkoutId;

        let installments = data.installments;
        (data.installments as any) = installments;

        socketService.emit('cart', {
          contentId: data.contentId,
          producerId: data.config.producerId,
          affiliateId: data.config.affiliateId,
          transactionKey
        });
        currencyService.setAvailableCurrencies(data.config.availableCurrencies);
        currencyService.setCurrency(data.config.currency);

        warmapCache(data);

        if (!IGNORE_CACHE && !data.isCached && !data.user && !data.upsell) {
          cacheService.setCache(CACHE_ID, data);
        }

        if (!data.isCached) {
          this.mounted = true;
        }
        /*
          legacyPixelService: Should be removed in not too distance future
        */
        legacyPixelService.setPixel({ ...data.config.pixel, contentId: data.contentId });

        paymentService.loadUserParameters({
          hasAddress: data.config.askAddressBeforePayment || data.config.askAddressBeforePayment,
          isEad: data.config.ead
        });

        if (!paymentData.checkoutId) {
          this.initializePayment(data.checkoutId, transactionKey, data, paymentData);
        }

        paymentService.loadParameters();

        if (isInternationalCurrency(currency)) {
          paymentService.setPaymentData(0, {
            numberInstallments: 1,
            installments: data.installments
          });
        }

        if (data.upsell) {
          this.initializePayment(isEcommerce || isRec ? data.contentId : data.originalCheckoutId, transactionKey, data, paymentData);
        }

        if (!data.config.enableCreditcard) {
          let pm = null;

          if (data.config.enablePaypal) {
            pm = enPaymentMethod.PAYPAL;
          }

          if (data.config.enablePaypal && isInternationalCurrency(currencyCart)) {
            pm = enPaymentMethod.PAYPAL;
          }

          if (data.config.enablePix && !isInternationalCurrency(currencyCart)) {
            pm = enPaymentMethod.PIX;
          }

          if (data.config.enableBankslip && !isInternationalCurrency(currencyCart)) {
            pm = enPaymentMethod.BANKSLIP;
          }

          if (data.config.paymentType === enPaymentType.FREE) {
            pm = enPaymentMethod.FREE;
          }

          if (pm) {
            paymentService.setPaymentData(0, {
              paymentMethod: pm
            });
          }

          if (!pm) {
            (data.config.enableCreditcard as any) = true;
          }
        }

        if (data.tickets) {
          ticketService.setTimeLeft(data.tickets.reservationData.expiresIn);
          ticketService.setReserveTime(data.tickets.reservationData.reserveTime);
        }

        configService.setConfig(data.config);

        this.emit(data);

        if (data.next) {
          if (!data.next.plans) {
            paymentService.setData({ next: { withoutLimitation: true } });
          }
        }

        paymentService.setPaymentData(0, { installments });

        if (data.upsell) {
          paymentService.setPaymentData(0, {
            paymentMethod: data.upsell,
            numberInstallments: 1
          });
        }

        if (data.installmentSelected > 0) {
          paymentService.setDefaultInstallment(data.installmentSelected);
        }

        return true;
      })
      .logError();

    return translateService
      .getTranslations()
      .take(1)
      .switchMap(() => observable);
  }

  public setSelectedInstallment(installment: number) {
    const newCart = { ...this.cartState, installmentSelected: installment };
    this.emit(newCart);
  }

  public setCart = (cart: IProductData, propriety: any) => {
    const newCart = {
      ...cart,
      ...propriety
    };

    this.emit(newCart);
  };

  public setIsAskAddressAfterPaymentStep(isAskAddressAfterPaymentStep: boolean) {
    this.isAskAddressAfterPaymentStep$.next(isAskAddressAfterPaymentStep);
  }

  public getIsAskAddressAfterPaymentStep() {
    return this.isAskAddressAfterPaymentStep$.asObservable().distinctUntilChanged();
  }

  public setPersonTypeByUserData() {
    this.cart$
      .take(1)
      .filter((cart) => !!cart?.user?.lock)
      .map((cart) => cart.user)
      .subscribe((user) => {
        paymentService.setData({ personType: user.personType || 'F' });
      });
  }

  public isContractSaleRecovery() {
    return this.cart$
      .take(1)
      .map((cart) => cart?.user?.lock)
      .switchMap((isUserDataEditLocked) => of(IS_ECOMMERCE && isUserDataEditLocked));
  }

  public getAcceptedSubscriptionTerm() {
    return this.acceptedSubscriptionTerm$.asObservable().distinctUntilChanged();
  }

  public setAcceptedSubscriptionTerm(accepted: boolean) {
    this.acceptedSubscriptionTerm$.next(accepted);
  }

  protected getInternalCart(): Observable<IProductData> {
    return this.uniqueCart$;
  }

  private createNewCart(cart: IProductData, coupon: ICouponData) {
    let cartCoupon = null;

    if (coupon.coupon) {
      cartCoupon = {
        code: coupon.coupon.code,
        value: coupon.coupon.value
      };
    }

    const newCart = {
      ...this.cartState,
      price: coupon.price,
      realPrice: coupon.realPrice,
      tax: coupon.tax,
      cartPrice: coupon.cartPrice,
      realTax: cart.realTax,
      taxedPrice: coupon.taxedPrice,
      coupon: cartCoupon,
      numberInstallments: coupon.installments.length,
      installments: coupon.installments,
      products: coupon.products,
      config: {
        ...this.cartState.config,
        maxInstallments: cart.config.maxInstallments || coupon.installments.length
      }
    };

    return newCart;
  }

  private initializePayment(checkoutId: string, ssid: string, data: IProductData, paymentData: ICartModel) {
    paymentService.setData({
      checkoutId,
      ssid,
      fullPrice: Number(data.price.toFixed(2)),
      quantity: data.products[0].quantity,
      productType: data.config.productType
    });

    parameterService
      .get(enParameters.p)
      .take(1)
      .pipe(
        tap((parameter) => {
          paymentService.setPaymentData(0, {
            installments: data.installments,
            numberInstallments: Number(parameter) || data.installmentSelected || data.numberInstallments,
            value: data.price
          });
        })
      )
      .combineLatest(paymentService.setAllowPayment())
      .subscribe(() => {});

    if (data.user) {
      const user = data.user || {};
      paymentService.setData({
        name: paymentData.name || user.name,
        email: paymentData.email || user.email,
        confirmEmail: paymentData.email || user.email,
        phone: paymentData.phone || user.phone,
        document: paymentData.document || user.document
      });

      const currentAddress = paymentData.address || {};
      const isContractSaleRecovery = IS_ECOMMERCE && user?.lock;

      if (user.postalCode && !isContractSaleRecovery) {
        if (paymentData.personType === 'E') {
          paymentService.setAddressData('address', {
            postalCode: currentAddress.postalCode || user.postalCode,
            street: currentAddress.street || user.street,
            number: currentAddress.number || user.number,
            district: currentAddress.district || user.district,
            complement: currentAddress.complement || user.complement,
            province: currentAddress.province || user.province,
            cityId: currentAddress.cityId || user.cityId.toString(),
            city: currentAddress.city || user.city,
            country: currentAddress.country || user.countryId.toString()
          });
        }

        if (paymentData.personType !== 'E') {
          paymentService.setAddressData('address', {
            postalCode: currentAddress.postalCode || user.postalCode,
            street: currentAddress.street || user.street,
            number: currentAddress.number || user.number,
            district: currentAddress.district || user.district,
            complement: currentAddress.complement || user.complement,
            province: currentAddress.province || user.province,
            cityId: currentAddress.cityId || user.cityId.toString(),
            city: currentAddress.city || user.city
          });
        }
      }

      if (user.creditCard) {
        paymentService.setPaymentData(0, {
          creditCardId: user.creditCard.id,
          brand: user.creditCard.brand
        });
      }
    }

    if (!!data?.student) {
      paymentService.setStudentData(data.student);
    }
  }

  private emit(data: IProductData) {
    if (data.upsell && (data.upsell as any) == 'boleto') {
      (data.upsell as any) = enPaymentMethod.BANKSLIP;
    }

    this.cartState = data;
    this.cart$.next(data);
  }

  private trackItemsGoofy() {
    this.getCart()
      .filter((cart) => !!cart && !!cart.products)
      .map((cart) => cart.products)
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .debounceTime(500)
      .subscribe((products) => {
        goofyService.track({
          flow: 'sale',
          trackerId: GOOFY_ID,
          step: 'cart_items',
          data: {
            items: products
          }
        });
      });
  }
}

// tslint:disable-next-line:variable-name
export const CartServiceFactory = () => new CartService();

export const cartService = CartServiceFactory();
