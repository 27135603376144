import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Grid from '@material-ui/core/Grid/Grid';
import MultipleInstallments from 'components/Cart/Price/MultipleInstallments';
import WithoutInstallment from 'components/Cart/Price/WithoutInstallment';
import { IProductData } from 'interfaces/IProductData';
import Installments from 'components/Cart/MainForm/Payment/PaymentForm/CreditCardForm/Installments';
import CreditCard from 'components/Cart/Templates/Mobile/OneClickBuy/CreditCard';
import { paymentService } from 'services/PaymentService';
import { cartService } from 'services/CartService';
import { Subscription } from 'components/Cart/Price/Subscription';
import AcceptTerms from 'components/AcceptTerms';
import { enPaymentType } from 'enums/enPaymentType';
import PaymentButton from 'components/Cart/PaymentButton';
import OrderBump from '../../../../OrderBump';
import OpenValue from 'components/Cart/OpenValue';
import Summary from '../../../../Summary/index';
import { ICartModel } from 'interfaces/ICartModel';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';
import FormHeader from 'components/Cart/FormHeader';
import Coupon from 'components/Cart/Coupon';
import OrderDetails from 'components/OrderDetails';
import { getPaymentButtonMessage } from 'functions';

interface IState extends IStateBase {
  payment: ICartModel;
  isMultipleCards: boolean;
  messageButton: string;
}

interface IProps {
  onPay?: any;
  cart?: IProductData;
  disableButton?: boolean;
}

export class ContentOneClickBuy extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = { payment: null, isMultipleCards: false, messageButton: '' };
  }

  componentDidMount() {
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());

    paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .bindComponent(this)
      .subscribe(([payment, cart, config]) => {
        this.setState({ messageButton: getPaymentButtonMessage(payment, cart, config) });
      });
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    const { cart } = this.props;
    const { payment, isMultipleCards, messageButton } = this.state;
    if (!this.state || !payment) {
      return null;
    }
    const isWithInstallments = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(payment.payment[0].paymentMethod);
    const numberInstallments = payment.payment[0].numberInstallments;

    return (
      <div>
        <FormHeader />
        <Grid className="chk-oneClickBuy__product" container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={6} className="chk-oneClickBuy__title">
            <h1 className="product-name">{cart.content.title}</h1>
          </Grid>
          <Grid item xs={6} className="chk-oneClickBuy__productPrice">
            {[enPaymentType.NORMAL, enPaymentType.ECOMMERCE].includes(cart.config.paymentType) &&
              (cart.config.enableCreditcard && isWithInstallments && numberInstallments > 1 && payment.payment.length == 1 && !isMultipleCards ? (
                <MultipleInstallments cart={cart} />
              ) : (
                <WithoutInstallment cart={cart} />
              ))}
            {cart.config.paymentType === enPaymentType.SUBSCRIPTION && <Subscription payment={payment} cart={cart} />}
          </Grid>
          <Grid item xs={12}>
            <OpenValue />
          </Grid>
        </Grid>
        <Grid item xs={12} className="chk-oneClickBuy__coupon">
          <Coupon />
        </Grid>
        <Grid item xs={12} className="chk-oneClickBuy__content">
          <div className="chk-oneClickBuy__form">
            <OrderDetails user={cart.user} />
            <CreditCard creditCard={cart.user.creditCard} askCVV={cart.config.askCVV} />
            <Installments index={0} />
            <Grid item xs={12}>
              <OrderBump />
              <Summary />
              {cart.config.paymentType == enPaymentType.SUBSCRIPTION && <AcceptTerms />}
              <PaymentButton finish={this.finish} disabledButton={this.props.disableButton} messageButton={messageButton} />
              <AcceptProducerTerms termsUrl={cart.config.termsUrl} />
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}
