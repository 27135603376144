/* eslint-disable max-lines */

import { BaseComponent } from 'components/BaseComponent';
import MainForm from 'components/Cart/MainForm';
import Price from 'components/Cart/Price';
import React from 'react';
import { cartService } from 'services/CartService';
import { History } from 'history';
import { Sidebar } from 'components/Cart/Sidebar';
import { ITemplateState, IPayProps, PayContext } from 'components/Cart';
import { HistoryContext } from 'shared/HistoryContext';
import { Observable } from 'rxjs/Observable';
import { Switch, Route } from 'react-router-dom';
import { paymentService } from 'services/PaymentService';
import AskAddress from 'components/Cart/AskAddress';
import { ICartModel } from 'interfaces/ICartModel';
import { SvgIcon } from 'components/SvgIcon';
import { enProductType } from 'enums/enProductType';
import PriceQuantity from 'components/Cart/PriceQuantity';
import Footer from '../../../Footer/index';
import { WithStyles } from 'shared/decorators/withStyles';
import { IS_SHOPIFY } from 'settings';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import Alert from 'shared/components/Alert';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import { historyService } from 'services/HistoryService';
import FormHeader from 'components/Cart/FormHeader';
import { thankYouService } from 'services/ThankYouService';

interface IState extends ITemplateState {
  payment: ICartModel;
  changeCard: boolean;
}

@WithStyles(() => ({
  component: {
    '& .chk-coupon': {
      '& Button': {
        height: '50px'
      },

      '& .field.normal': {
        '& label': {
          fontSize: '0.75rem'
        },

        '& label[data-shrink="true"], & label[data-shrink="false"]': {
          zIndex: 1,
          transform: 'scale(1) translate(0, -3px)'
        }
      }
    },

    '& .field.normal, & .chk-cityState__state, & .chk-cityState__city, & .chk-userAddressInternational__country': {
      margin: '8px 0 0 0',
      fontSize: '1.125rem',

      '& .br': {
        padding: 0
      },

      '& input': {
        border: 'none',
        fontSize: '1.125rem',

        '&:focus': {
          border: '1px solid rgba(0, 0, 0, .5) !important',
          transition: '0.2s linear'
        }
      },

      '& label': {
        fontSize: '1rem'
      },

      '& label[data-shrink="true"], & label[data-shrink="false"]': {
        transform: 'translate(0, -4px) scale(1)'
      }
    },

    '& .chk-userAddressInternational__label': {
      transform: 'translate(0, -4px) scale(1)'
    },

    '& .chk-installments__installmentFieldSelect': {
      '& label': {
        transform: 'scale(1) translate(0, -3px)'
      },

      '& .field-select': {
        fontSize: '1rem',
        boxShadow: 'none'
      }
    },

    '& .chk-header': {
      overflow: 'hidden',

      '& img': {
        width: '100%',
        maxWidth: '100%',
        maxHeight: '280px'
      }
    },

    '& .chk-container': {
      display: 'table',
      width: '100%',
      marginTop: '20px',

      '& .chk-siderbar-form': {
        display: 'flex'
      },

      '& .chk-form': {
        float: 'left',
        padding: '20px',
        margin: '0 20px 0 0',
        backgroundColor: '#fff',
        borderRadius: '4px',
        width: 'calc(100% - 370px)',
        border: '1px solid #e8e7e7'
      },

      '& .chk-sidebar': {
        float: 'left',
        width: '350px',
        height: '100%',
        flexDirection: 'column',

        '@media screen and (maxWidth: 840px)': {
          display: 'none'
        }
      }
    },

    '& .chk-cityState__city': {
      margin: '8px 0 8px 0'
    },

    '& .chk-cityState__state': {
      marginTop: '8px'
    },

    '& .chk-userAddressInternational__country .theme-input': {
      padding: '10px 8px'
    },

    '& .chk-payment-flags': {
      height: '60px',
      justifyContent: 'center'
    },

    '& .chk-payment-option': {
      margin: '0 0 16px 0'
    },

    '& .chk-installments-wrapper .chk-installments__value': {
      fontSize: '1rem',
      margin: 0
    },

    '& .links': {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      margin: '8px 0',
      width: '100%',

      '& a, & .chk-installment-fee': {
        display: 'inline-block',
        textDecoration: 'none',
        color: '#3C3C3C',

        '&:after': {
          content: '"|"',
          display: 'inline-block',
          margin: '0 5px'
        }
      },

      '& a:last-child:after': {
        content: '""'
      },

      '& .chk-media-footer': {
        fontSize: '.75rem',
        textAlign: 'center',
        color: 'black',
        textShadow: '1px 1px 2px #fff'
      }
    },

    '& .eduzz-logo': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(100% - 370px)',
      marginBottom: '10px',

      '& svg': {
        width: '130px'
      },

      '& span': {
        fontSize: '0.75rem'
      },

      '& img': {
        maxWidth: '120px'
      }
    },

    '& .chk-subscription': {
      '& .chk-subscription__text': {
        fontSize: '0.8125rem',
        textTransform: 'uppercase'
      },

      '& .chk-subscription__value': {
        fontSize: '1.5rem'
      },

      '& .chk-subscription__frequency, & .chk-subscription__trial': {
        fontSize: '0.875rem'
      },

      '& .chk-subscription__trial': {
        padding: '4px 0'
      },

      '& .chk-subcription__trial__free': {
        '& p': {
          color: 'green'
        }
      }
    },

    '& .chk-siderbar-form': {
      '& .chk-askAdress__content': {
        '& .form': {
          padding: 0
        },

        '& .addressAfter': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',

          '& .icon': {
            marginRight: '20px'
          },

          '& .chk-paragraph': {
            '& .title': {
              color: '#000',
              fontSize: '1em',
              textAlign: 'left',
              marginBottom: '5px'
            },

            '& .label': {
              fontSize: '0.8em',
              color: '#000',
              marginTop: 0
            }
          }
        }
      }
    }
  }
}))
export default class SingleStep extends BaseComponent<IState, IPayProps> {
  private history: History;

  public componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ payment: null }, paymentService.getCart());

    cartService
      .getCart()
      .filter((cart) => !cart.isCached && cart.upsell == enPaymentMethod.BANKSLIP && cart.config.productType != enProductType.PHYSICAL)
      .take(1)
      .subscribe(() => {
        this.onPay();
      });

    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));
  }

  public onPay = () => {
    if (paymentService.hasPaymentInProgress()) {
      return;
    }

    this.props
      .onPay()
      .switchMap(() => cartService.getCart())
      .combineLatest(paymentService.shouldBlockAddressAfterPayment())
      .take(1)
      .switchMap(([cart, shouldBlockAddressAfterPayment]) => {
        if (cart.config.askAddressAfterPayment && !shouldBlockAddressAfterPayment) {
          if (IS_SHOPIFY) {
            return Observable.of('/a/sun/askaddress');
          }
          return Observable.of(`/${cart.contentId}/askaddress`);
        }

        return paymentService.getPaymentResult().map((result) => {
          return thankYouService.redirectThankYou(result);
        });
      })
      .take(1)
      .filter((url) => url !== null)
      .bindComponent(this)
      .subscribe((url) => {
        this.history.push(url);
      });
  };

  setHistory = (history: History): any => {
    this.history = history;
  };

  public mainForm = () => {
    return <MainForm onPay={this.onPay} />;
  };

  public render() {
    if (!this.state) {
      return null;
    }

    const { cart, payment, changeCard } = this.state;
    const { classes } = this.props;
    const numberPayments = payment.payment.length;
    const numberInstallments = payment.payment[0].numberInstallments;
    const isStepAskAddressAfterPayment = historyService.hasAskAddressAfterPaymentInUrl();

    return (
      <div className={classes.component}>
        <PayContext.Provider value={this.onPay.bind(this)}>
          <HistoryContext.Consumer>{(h) => this.setHistory(h)}</HistoryContext.Consumer>
          <section className="chk-main-container">
            <section className="chk-container">
              <div className="chk-form">
                <FormHeader />
                {changeCard && <Alert message="Atenção! Este checkout irá alterar a sua forma de pagamento das próximas assinaturas." />}
                {cart.config.productType == enProductType.TICKET && !isStepAskAddressAfterPayment ? (
                  <PriceQuantity />
                ) : (
                  <Price
                    showConfigSelectors
                    showBorder={false}
                    cart={cart}
                    hideMultipleInstallments={numberPayments > 1 || numberInstallments <= 1}
                  />
                )}
                <div className="chk-siderbar-form">
                  <Switch>
                    <Route path="/a/sun/askaddress" component={AskAddress} exact />
                    <Route path="/a/sun" render={this.mainForm} exact />
                    <Route path="/:id/askaddress" component={AskAddress} exact />
                    <Route path="/:id" render={this.mainForm} />
                  </Switch>
                </div>
              </div>
              <div className="chk-sidebar">
                <Sidebar cart={cart} />
              </div>
            </section>
          </section>

          <div className="eduzz-logo">
            <div className="links">
              <Footer />
            </div>
            <SvgIcon name="eduzzLogo" />
          </div>
        </PayContext.Provider>
      </div>
    );
  }
}
