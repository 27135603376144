/* eslint-disable max-lines */
import { ENV, GATEWAY_SECRETS, IS_DEVELOPMENT } from 'settings';
import * as Sentry from '@sentry/browser';
import { client as braintree } from 'braintree-web';
import { configService } from './ConfigService';

export interface IHashConfig {
  id: number | string;
  type: 'mercadopago' | 'iugu' | 'braintree' | 'adyen' | 'pagarme';
  key: string;
}

export interface ICardInfo {
  email: string;
  number: string;
  month: string;
  year: string;
  name: string;
  cvv: string;
  document: string;
}

export interface IHashResult {
  id: number | string;
  hash: string;
}

const WAIT_FOR_CARD_MAX_TIME = 20000;

class CardHashService {
  public constructor(private readonly configs: IHashConfig[]) {}

  public async generateHashes(cardInfo: ICardInfo) {
    const productConfig = await configService.getConfig().take(1).toPromise();

    const groupsMap = this.configs.reduce((acc, item) => {
      if (!productConfig.enablePaymentWithoutLimit && String(item.id).match('WITHOUT_LIMIT') !== null) {
        return acc;
      }

      acc[item.type] = acc[item.type] || [];

      acc[item.type].push(item);

      return acc;
    }, {} as { [key: string]: IHashConfig[] });

    const groups = Object.values(groupsMap);

    const promises = groups.map((group) => this.generateHashByGroups(group, cardInfo));

    const results = await Promise.all(promises);

    const tokens = results.flatMap((r) => r);

    return tokens.reduce((acc, item) => {
      acc[item.id] = item.hash;
      return acc;
    }, {} as { [key: number]: string });
  }

  private async generateHashByGroups(group: IHashConfig[], cardInfo: ICardInfo) {
    const results: IHashResult[] = [];

    for (let config of group) {
      const result = await this.generateHash(config, cardInfo);
      results.push(result);
    }

    return results;
  }

  private async generateHash(config: IHashConfig, cardInfo: ICardInfo): Promise<IHashResult> {
    switch (config.type) {
      case 'mercadopago':
        return this.generateHashMercadoPago(config, cardInfo);
      case 'iugu':
        return this.generateHashIugu(config, cardInfo);
      case 'braintree':
        return this.generateHashBraintree(config, cardInfo);
      case 'adyen':
        return this.generateHashAdyen(config, cardInfo);
      case 'pagarme':
        return this.generateHashPagarme(config, cardInfo);
      default:
        return {
          id: 0,
          hash: ''
        };
    }
  }

  private async generateHashMercadoPago(config: IHashConfig, cardInfo: ICardInfo): Promise<IHashResult> {
    if (!window.MercadoPago) {
      return {
        id: config.id,
        hash: ''
      };
    }

    const payload = {
      email: cardInfo?.email,
      cardNumber: cardInfo?.number,
      securityCode: cardInfo?.cvv,
      expirationDate: `${cardInfo?.month}/${cardInfo?.year}`,
      cardExpirationMonth: cardInfo?.month,
      cardExpirationYear: `20${cardInfo?.year}`,
      cardholderName: cardInfo?.name,
      identificationType: (cardInfo?.document || '').length === 14 ? 'CNPJ' : 'CPF',
      identificationNumber: cardInfo?.document
    };

    const mp = new window.MercadoPago(config.key);

    const hash = await mp
      .createCardToken(payload)
      .then((result: { id: string }) => result.id)
      .catch((err: any) => {
        this.sendToSentry({ ...err, tag: 'MP' });
        return '';
      });

    return {
      id: config.id,
      hash
    };
  }

  private async generateHashIugu(config: IHashConfig, cardInfo: ICardInfo): Promise<IHashResult> {
    if (!window?.Iugu) {
      return Promise.resolve({
        id: config.id,
        hash: ''
      });
    }
    const names = cardInfo.name.split(/\s+/);

    const name = names[0];
    const lastName = names[names.length - 1];

    return new Promise<IHashResult>((resolve) => {
      const requesMaxtime = setTimeout(() => {
        this.sendToSentry({ tag: 'IUGU-empty' });
        resolve({
          id: config.id,
          hash: ''
        });
      }, WAIT_FOR_CARD_MAX_TIME);

      window.Iugu.setAccountID(config.key);
      window.Iugu.setTestMode(IS_DEVELOPMENT);

      const card = window.Iugu.CreditCard(cardInfo.number, cardInfo.month, cardInfo.year, name, lastName, cardInfo.cvv);

      window.Iugu.createPaymentToken(card, (body: any) => {
        clearTimeout(requesMaxtime);
        if (body?.errors?.timeout === 'operation_timeout') {
          Sentry.setTag('deepError', 'IUGU_TOKENIZATION_TIMEOUT');
        }
        if (!body || body.errors) {
          this.sendToSentry({ base: JSON.stringify(body) || '', tag: 'IUGU' });
          resolve({
            id: config.id,
            hash: ''
          });
          return;
        }
        resolve({
          id: config.id,
          hash: body.id as string
        });
      });
    });
  }

  private async generateHashBraintree(config: IHashConfig, cardInfo: ICardInfo): Promise<IHashResult> {
    return new Promise<IHashResult>(async (resolve) => {
      braintree
        .create({
          authorization: config.key
        })
        .then((client) => {
          client.request(
            {
              endpoint: 'payment_methods/credit_cards',
              method: 'post',
              data: {
                creditCard: {
                  number: cardInfo.number,
                  expirationDate: `${cardInfo.month}/${cardInfo.year}`,
                  cvv: cardInfo.cvv
                }
              }
            },
            (requestErr: any, response: any) => {
              if (requestErr) {
                this.sendToSentry({ ...requestErr, tag: 'BT' });
                resolve({
                  id: config.id,
                  hash: ''
                });
              } else {
                resolve({
                  id: config.id,
                  hash: response.creditCards[0].nonce
                });
              }
            }
          );
        })
        .catch((err) => {
          this.sendToSentry({ ...err, tag: 'BT' });
          resolve({
            id: config.id,
            hash: ''
          });
        });
    });
  }

  private async generateHashAdyen(config: IHashConfig, cardInfo: ICardInfo): Promise<IHashResult> {
    const adyen = await import('adyen-cse-web');

    const adyenCartData = {
      number: cardInfo?.number,
      cvc: cardInfo?.cvv,
      holderName: cardInfo?.name,
      expiryMonth: cardInfo?.month,
      expiryYear: `20${cardInfo?.year}`,
      generationtime: new Date().toISOString()
    };

    return new Promise<IHashResult>((resolve) => {
      const cse = adyen.createEncryption(config.key, {});
      const adyenHash = cse.encrypt(adyenCartData);
      resolve({
        id: config.id,
        hash: adyenHash
      });
    });
  }

  private async generateHashPagarme(config: IHashConfig, cardInfo: ICardInfo): Promise<IHashResult> {
    if (!window.pagarme) {
      return {
        id: config.id,
        hash: ''
      };
    }

    /* eslint-disable camelcase */
    const card = {
      card_number: cardInfo?.number,
      card_holder_name: cardInfo?.name,
      card_expiration_date: `${cardInfo?.month}${cardInfo?.year}`,
      card_cvv: cardInfo?.cvv
    };

    const pagarmeHash = await window.pagarme.client
      .connect({ encryption_key: config.key })
      .then((client: any) => client.security.encrypt(card))
      .then((card_hash: any) => {
        return card_hash;
      })
      .catch(() => {
        return '';
      });

    return {
      id: config.id,
      hash: pagarmeHash
    };
    /* eslint-enable camelcase */
  }
  private sendToSentry(data: any) {
    Sentry.setTag('error', 'empty-token');

    data = { ...data, date: new Date() };
    Sentry.addBreadcrumb({
      category: 'info',
      level: Sentry.Severity.Error,
      data
    });

    Sentry.captureMessage(`[${data.tag ?? ''}] Erro ao gerar hash card!!!`, {
      level: Sentry.Severity.Error,
      extra: data
    });
  }
}
export const cardHashService = new CardHashService(GATEWAY_SECRETS[ENV]);
