import Grid from '@material-ui/core/Grid/Grid';
import { getCdnImage, unescapeHtml } from 'functions';
import React from 'react';
import { cartService } from 'services/CartService';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import ShowDetails from './ShowDetails';
import { paymentService } from 'services/PaymentService';
import { useObservable } from 'react-use-observable';
export interface IProps {
  shouldRenderModal: boolean;
}

export const HeaderTitle: React.FC<IProps> = React.memo((props) => {
  const [data] = useMappedObservable(
    () => cartService.getCart().combineLatest(cartService.getConfig()),
    ([cart, config]) => {
      return {
        logo: config.logo,
        title: cart.content.title
      };
    },
    []
  );
  const [cart] = useObservable(() => cartService.getCart(), []);
  const [payment] = useObservable(() => paymentService.getCart(), []);

  if (!data || !cart || !payment) {
    return null;
  }

  const image = getCdnImage(data.logo);
  const { shouldRenderModal } = props;

  return (
    <Grid container justify="flex-start">
      <Grid container item xs={12} sm={12} wrap="nowrap">
        <Grid item>{image && <img className="chk-img" src={image} id="logo-content" />}</Grid>
        <Grid item zeroMinWidth style={{ marginLeft: 8 }}>
          <h1 className="product-name theme-label">{unescapeHtml(data.title)}</h1>
          {shouldRenderModal && <ShowDetails />}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default HeaderTitle;
