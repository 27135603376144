import currencyFormatter from 'currency-formatter';
import React, { useCallback } from 'react';
import { currencyService } from 'services/CurrencyService';
import { useObservable } from 'react-use-observable';
import { ZERO_DECIMALS_CURRENCIES } from 'data/currency';
import { enCurrency } from 'enums/enCurrency';

interface IProps {
  value: number;
}

export const CurrencyFormat: React.FC<IProps> = React.memo(({ value }) => {
  const [currency] = useObservable(() => currencyService.getCurrency(), []);

  const getPrecisionByCurrency = useCallback((currency: enCurrency) => {
    return ZERO_DECIMALS_CURRENCIES.includes(currency) ? 0 : 2;
  }, []);

  if (!currency) {
    return null;
  }

  const formattedValue = currencyFormatter.format(value, { code: currency, precision: getPrecisionByCurrency(currency) });

  return <span>{formattedValue}</span>;
});
