import React from 'react';
import { IProductData } from 'interfaces/IProductData';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { BaseComponent } from 'components/BaseComponent';
import { enProductType } from 'enums/enProductType';
import { Translate } from 'components/Translate';
interface IPriceProps {
  cart: IProductData;
}

export default class International extends BaseComponent<null, IPriceProps> {
  render() {
    const { taxedPrice } = this.props.cart;
    const product = this.props.cart.products[0];

    if (product.totalPrice === 0 && product.type === enProductType.PHYSICAL) {
      return (
        <div className="chk-full">
          <span className="chk-full-price theme-label">
            <Translate term="Grátis" />
          </span>
          <div className="chk-parts without-installments">
            <div className="chk-small-price">
              <Translate term="Frete de" /> <CurrencyFormat value={taxedPrice} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="chk-full">
        <span className="chk-full-price theme-label" style={{ position: 'relative' }} id="chk-full-price-internacional">
          <CurrencyFormat value={taxedPrice} />
        </span>
      </div>
    );
  }
}
